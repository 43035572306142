/* ======================================================================== */
/* 110. margins */
/* ======================================================================== */
@for $i from 0 through 6
	.m-#{$i}
		margin: #{$i}em
	.mt-#{$i}
		margin-top: #{$i}em
	.mr-#{$i}
		margin-right: #{$i}em
	.mb-#{$i}
		margin-bottom: #{$i}em
	.ml-#{$i}
		margin-left: #{$i}em
	.my-#{$i}
		margin-top: #{$i}em
		margin-bottom: #{$i}em
	.mx-#{$i}
		margin-left: #{$i}em
		margin-right: #{$i}em
	// minus
	.m-minus-#{$i}
		margin: -#{$i}em
	.mt-minus-#{$i}
		margin-top: -#{$i}em
	.mr-minus-#{$i}
		margin-right: -#{$i}em
	.mb-minus-#{$i}
		margin-bottom: -#{$i}em
	.ml-minus-#{$i}
		margin-left: -#{$i}em
	.my-minus-#{$i}
		margin-top: -#{$i}em
		margin-bottom: -#{$i}em
	.mx-minus-#{$i}
		margin-left: -#{$i}em
		margin-right: -#{$i}em
	@media screen and (min-width: $sm + 1)
		.m-sm-#{$i}
			margin: #{$i}em
		.mt-sm-#{$i}
			margin-top: #{$i}em
		.mr-sm-#{$i}
			margin-right: #{$i}em
		.mb-sm-#{$i}
			margin-bottom: #{$i}em
		.ml-sm-#{$i}
			margin-left: #{$i}em
		.my-sm-#{$i}
			margin-top: #{$i}em
			margin-bottom: #{$i}em
		.mx-sm-#{$i}
			margin-left: #{$i}em
			margin-right: #{$i}em
		// minus
		.m-sm-minus-#{$i}
			margin: -#{$i}em
		.mt-sm-minus-#{$i}
			margin-top: -#{$i}em
		.mr-sm-minus-#{$i}
			margin-right: -#{$i}em
		.mb-sm-minus-#{$i}
			margin-bottom: -#{$i}em
		.ml-sm-minus-#{$i}
			margin-left: -#{$i}em
		.my-sm-minus-#{$i}
			margin-top: -#{$i}em
			margin-bottom: -#{$i}em
		.mx-sm-minus-#{$i}
			margin-left: -#{$i}em
			margin-right: -#{$i}em
	@media screen and (min-width: $md + 1)
		.m-md-#{$i}
			margin: #{$i}em
		.mt-md-#{$i}
			margin-top: #{$i}em
		.mr-md-#{$i}
			margin-right: #{$i}em
		.mb-md-#{$i}
			margin-bottom: #{$i}em
		.ml-md-#{$i}
			margin-left: #{$i}em
		.my-md-#{$i}
			margin-top: #{$i}em
			margin-bottom: #{$i}em
		.mx-md-#{$i}
			margin-left: #{$i}em
			margin-right: #{$i}em
		// minus
		.m-md-minus-#{$i}
			margin: -#{$i}em
		.mt-md-minus-#{$i}
			margin-top: -#{$i}em
		.mr-md-minus-#{$i}
			margin-right: -#{$i}em
		.mb-md-minus-#{$i}
			margin-bottom: -#{$i}em
		.ml-md-minus-#{$i}
			margin-left: -#{$i}em
		.my-md-minus-#{$i}
			margin-top: -#{$i}em
			margin-bottom: -#{$i}em
		.mx-md-minus-#{$i}
			margin-left: -#{$i}em
			margin-right: -#{$i}em
.mt-0-5
	margin-top: 0.5em
.mb-0-5
	margin-bottom: 0.5em

.mr-auto
	margin-right: auto !important
.mx-auto
	margin-right: auto !important
	margin-left: auto !important
.my-auto
	margin-top: auto !important
	margin-bottom: auto !important
.ml-auto
	margin-left: auto !important
.mt-auto
	margin-top: auto !important
.mb-auto
	margin-bottom: auto !important
