/* ======================================================================== */
/* 49. artsParallax */
/* ======================================================================== */
[data-arts-parallax]
	position: relative
	overflow: hidden
	img
		width: 100%
		height: 100%
		object-fit: cover
	> div
		display: block
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
