/* ======================================================================== */
/* 51. pagination */
/* ======================================================================== */
.pagination
	@include fluid('margin-top', 40, 90)
	border-top: 2px solid $black
	padding: 20px 0 0
	font-family: $font-secondary
	.nav-links
		display: flex
		justify-content: space-between
		align-items: center
	.nav-links__container
		margin-left: auto
		margin-right: auto
	.page-numbers
		display: inline-block
		line-height: 1
		font-weight: 600
		margin: 0 15px
		background-image: none
		vertical-align: middle
		&.prev
			font-size: 20px
			font-weight: normal
			margin-left: 0
		&.next
			font-size: 20px
			font-weight: normal
			margin-right: 0
		&:not(a)
			color: $brown-grey
.page-links
	@include fluid('margin-top', 40, 90)
	border-top: 2px solid $black
	padding: 20px 0 0
	.page-number
		display: inline-block
		line-height: 1
		font-weight: 600
		margin: 0 15px
		border: none
	a
		background-image: none
	.post-page-numbers
		&:not(a)
			color: $brown-grey

.comments-pagination
	text-align: center
	.page-numbers
		display: inline-block
		line-height: 1
		font-weight: 600
		margin: 0 20px

@media screen and (max-width: $md)
	.pagination
		padding: 10px 0 0
	.page-links
		padding: 10px 0 0
